import React from "react";
import styled from "styled-components";
import scrollTo from 'gatsby-plugin-smoothscroll';

const BtnContainer = styled.article`
  width: 100vw;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
`

const BtnScroll = styled.div`
  background-color: rgba(46, 47, 50, 0.88);
  height: 32px;
  width: 32px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  font-family: "Perroquet-Bold", Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 0.88);

  cursor: pointer;
  transform: rotate(-90deg);

  transition: background-color 0.55s ease-in-out, transform  0.55s ease-in-out;

  &:hover {
    background-color: rgba(119, 0, 255, 0.88);
    transform: rotate(-450deg);
  }
`

const ScrollToTopBtn = ({ clicked }) => {
  return (
    <BtnContainer>
      <BtnScroll onClick={() => scrollTo(clicked)}>{">"}</BtnScroll>
    </BtnContainer>
  )
}

export default ScrollToTopBtn
