import React, { Component } from 'react';
import styled from "styled-components";
import { injectIntl } from "gatsby-plugin-intl";

const MentionContainer = styled.article`
  margin: 3rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
`

const MentionTextContainer = styled.div`
  width: auto;
  margin-bottom: 25px;
`

const MentionText = styled.p`
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  color: #777 !important;
  font-size: 10pt !important;
  text-transform: uppercase;
  margin-bottom: 25px;
  letter-spacing: 1px;
`

const MentionTextDeux = styled.p`
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  color: #777 !important;
  font-size: 10pt !important;
  text-transform: uppercase;
  letter-spacing: 1px;
`

class MentionsLegales extends Component {
  render() {
    return (
      <MentionContainer id="mentionsLeg">
        <MentionText>{this.props.intl.formatMessage({ id: "mention" })}</MentionText>
        <MentionText>{this.props.intl.formatMessage({ id: "mention1" })}</MentionText>

        <MentionTextContainer>
          <MentionTextDeux>{this.props.intl.formatMessage({ id: "Nom" })} Perroquet</MentionTextDeux>
          <MentionTextDeux>{this.props.intl.formatMessage({ id: "adresse" })} 37 rue Guibal, 13001 MARSEILLE</MentionTextDeux>
          <MentionTextDeux>{this.props.intl.formatMessage({ id: "numero" })} 04 91 05 50 55</MentionTextDeux>
          <MentionTextDeux>{this.props.intl.formatMessage({ id: "adresse" })} contact@perroquet.eu</MentionTextDeux>
        </MentionTextContainer>

        <MentionTextContainer>
          <MentionTextDeux>{this.props.intl.formatMessage({ id: "forme" })} Micro-entreprise</MentionTextDeux>
          <MentionTextDeux>SIREN : 537 879 538</MentionTextDeux>
          <MentionTextDeux>Code APE : 7410 Z</MentionTextDeux>
          <MentionTextDeux>OPCO : ATLAS</MentionTextDeux>
        </MentionTextContainer>

        <MentionText>{this.props.intl.formatMessage({ id: "directeur" })} Clément CATHERINE.</MentionText>
        <MentionText>Hébergeur : OVH, 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX</MentionText>
      </MentionContainer>
    );
  }
}

export default injectIntl(MentionsLegales)