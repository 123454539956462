import { injectIntl } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from "styled-components"
import "../animations.module.scss"
import MentionsLegales from "./MentionsLegales/MentionsLegales"

const FooterContainer = styled.footer`
  width: 96vw;
  margin: 0 1rem;

  @media screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }
`
const FooterSection = styled.section`
  display: flex;
  flex-direction: column;
`
const FooterArticle = styled.article`
  display: flex;
  flex-wrap: wrap;
`

const FooterTitle = styled.h4`
  margin-bottom: 1.4rem;
  color: #777 !important;
  letter-spacing: 1px;
  font-size: 10pt !important;
  margin-top: 2rem;

  @media screen and (min-width: 768px) {
    margin-top: 1.5rem;
  }
`

const FooterAnchor = styled.a`
  width: fit-content;
  border-radius: 1.75px;
  background-color: rgba(46, 47, 50, 0.88);
  margin-right: 0.3rem;
  margin-bottom: 0.3rem !important;
  padding: 0.175rem 0.5rem 0.1rem 0.5rem;
  display: block !important;
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.88);
  line-height: auto;
  text-decoration: none;
  overflow-wrap: break-word;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
  cursor: pointer;
  transition: all .5s, color .5s ease;

  &&:hover {
    color: #fff !important;
    background-color: rgba(119, 0, 255, 0.88) !important;
  }
`

const FooterCopyright = styled.p`
  width: 96vw;
  padding: 1.5rem 1rem 1rem 1rem;
  font-family: "Perroquet-regular",Arial, Helvetica, sans-serif;
  font-size: 10pt !important;
  letter-spacing: 1px;
  color: #777 !important;

  @media screen and (min-width: 768px) {
    font-size: 11pt !important;
  }
`

const FooterInfos = ({ intl }) => {

  const [isMentions, setIsMentions] = useState(false);

  const todayDate = new Date();
  const thisYear = todayDate.getFullYear();

  const handleMention = () => {
    setIsMentions(!isMentions);

    setTimeout(function () {
      return scrollTo("#mentionsLeg")
    }, 10);
  }

  return (
    <>
      <FooterContainer>
        <FooterSection>
          <FooterTitle>{intl.formatMessage({ id: "informations" })}</FooterTitle>

          <FooterArticle>
            <FooterAnchor onClick={handleMention} style={{ backgroundColor: isMentions ? "#7500ff" : "" }}>
            {intl.formatMessage({ id: "mentionsLégales" })}
            </FooterAnchor>

            <FooterAnchor target="_blank" rel="noreferrer noopener" href="https://www.google.fr/maps/place/Perroquet/@43.309322,5.3892108,15z/data=!4m5!3m4!1s0x0:0xd6ddffceb69a6976!8m2!3d43.309322!4d5.3892108">37 RUE GUIBAL, 13001 MARSEILLE</FooterAnchor>

            <FooterAnchor href="mailto:contact@perroquet.eu">CONTACT@PERROQUET.EU</FooterAnchor>
            <FooterAnchor href="tel:0491055055">04 91 05 50 55</FooterAnchor>
          </FooterArticle>
        </FooterSection>

        <FooterSection>
          <FooterTitle>{intl.formatMessage({ id: "suivez-nous" })}</FooterTitle>

          <FooterArticle>
            <FooterAnchor target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/perroquetbooked">FACEBOOK</FooterAnchor>
            <FooterAnchor target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/perroquet/">INSTAGRAM</FooterAnchor>
            <FooterAnchor target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/perroquet-design-graphique/">LINKEDIN</FooterAnchor>
            <FooterAnchor target="_blank" rel="noreferrer noopener" href="https://twitter.com/perroquetwitted">TWITTER</FooterAnchor>
          </FooterArticle>
        </FooterSection>
      </FooterContainer>

      {isMentions && <MentionsLegales />}

      <FooterCopyright>{intl.formatMessage({ id: "copyright" })} - {thisYear}</FooterCopyright>
    </>
  )
}

export default injectIntl(FooterInfos)